<template>
  <div ref="engagementRef" class="relative">
    <div class="justify-center">
      <div
        v-if="postObject"
        class="flex w-[100%] mt-2 text-xl pb-1 cursor-pointer select-none items-center justify-between"
        :class="{
          'text-sm mt-0 pb-0 ml-2 w-[95%] ': isCommentDetails,
          '': !isCommentDetails,
        }"
        @click="navigateToContent(postObject.id)"
      >
        <ParlerReactions
          v-if="globalStore.isParler"
          :isCommentDetails="isCommentDetails"
          :parentItem="postObject"
          :isNestedFeed="isNestedFeed"
          :toastOpen="toastOpen"
          @showToast="handleShowToast"
          @show-details-reactions="showDetailsReactions = !showDetailsReactions"
          @closeToast="closeToast"
          :quote="quote"
        />
        <ReactionBar
          v-else
          :isCommentDetails="isCommentDetails"
          :parentItem="postObject"
          :isNestedFeed="isNestedFeed"
          @showToast="handleShowToast"
          :quote="quote"
        />

        <div
          class="flex items-center text-center text-sm space-x-3"
          :class="[
            depth > 2 && containerWidth < 364
              ? depth > 5 && containerWidth < 364
                ? 'pr-4'
                : 'pr-3'
              : '',
          ]"
        >
          <SharePopup
            v-if="!globalStore.isParler"
            :parentItem="postObject"
            :isCommentDetails="isCommentDetails"
            @showToast="handleShowToast"
          />
          <div class="flex">
            <div
              class="border border-gray-300 dark:border-gray-500 flex rounded-l-full items-center justify-center space-x-1 px-1 h-6 min-w-12 text-[1rem] align-middle"
              :class="{
                'rounded-r-full mr-4': parentPost?.postType === 'COMMENT',
              }"
              @click.stop.prevent="openCommentAccordion"
            >
              <ion-icon
                title="Comments"
                :src="commentAccordion ? comment : commentOutline"
                :class="{ active: commentAccordion }"
                class="cursor-pointer select-none"
              />
              <FormatCount
                class="text-sm"
                v-if="postObject.postEngagement?.totalCommentCount > 0"
                :count="postObject.postEngagement?.totalCommentCount"
              />
            </div>
            <SharePopup
              v-if="globalStore.isParler && parentPost?.postType !== 'COMMENT'"
              :parentItem="postObject"
              :isCommentDetails="isCommentDetails"
              :repostId="repostId"
              @showToast="handleShowToast"
            />
          </div>
          <ion-icon
            v-if="!globalStore.isParler"
            @click.stop.prevent="replyAccordion = !replyAccordion"
            :icon="replyAccordion ? arrowUndoSharp : arrowUndoOutline"
            class="text-xl transform scale-y-[-1] cursor-pointer select-none"
            :class="{
              active: postObject?.userEngagement?.hasCommented,
              'text-xl': isCommentDetails,
            }"
          />
        </div>
      </div>
      <DetailsReactions
        v-if="!quote"
        :parentItem="postObject"
        :open="showDetailsReactions"
        @closeDetails="showDetailsReactions = false"
      />
      <div
        v-if="commentAccordion && globalStore.isParler"
        :class="isCommentDetails ? 'w-full justify-end p-2 pr-4' : 'p-2'"
        class="flex"
        @click.stop.prevent="replyAccordion = !replyAccordion"
      >
        <div
          v-if="!isCommentDetails"
          class="mr-2 flex-none rounded-full h-10 w-10 object-cover"
          aria-disabled="true"
        >
          <UserAvatar :avatar="authStore.currentUser.avatar" />
        </div>
        <div
          class="text-sm"
          :class="
            isCommentDetails
              ? 'text-white p-px px-2 rounded-lg primary-color'
              : 'w-full h-10 border-2 rounded-md  bg-gray-200 p-2'
          "
        >
          <p :class="isCommentDetails ? 'text-center dark:text-white' : ''">
            {{ isCommentDetails ? "Reply" : "Write a comment..." }}
          </p>
        </div>
      </div>
    </div>
    <create-post-popup
      v-if="replyAccordion"
      :is-opened="replyAccordion"
      :is-comment="true"
      @close="replyAccordion = false"
      :originalPost="postObject"
      :commentSort="currentFilter"
      :replyId="replyId"
      @new-comment="handleNewComment"
    />

    <Transition>
      <div
        v-if="commentAccordion && parentPost?.postType !== 'COMMENT'"
        ref="commentAccordionRef"
      >
        <div
          v-if="postObject.postEngagement?.commentCount > 0"
          class="w-full comment-feed"
          :class="{
            'pb-12': detailsShow,
          }"
        >
          <CommentFeed
            ref="commentFeedRef"
            :detailsShow="detailsShow"
            :parentId="parentId"
            :parentItem="postObject"
            :isNestedFeed="isNestedFeed"
            :parentFilter="parentFilter"
            :parentSort="parentSort"
            :depth="depth + 1"
            @comment-ids="addCommentIds"
            :loadMore="atBottom"
            @scroll-into-view="scrollInToView"
            @new-filter="handleNewFilter"
            @no-more-comments="handleNoMoreComments"
            @comments-in-dom="handleCommentsInDom"
          />
        </div>
      </div>
    </Transition>
  </div>
  <ion-toast
    :is-open="toastOpen"
    :message="toastMessage"
    :duration="toastDuration"
    :color="toastColor"
  />
</template>

<script setup>
import { ref, computed, nextTick, watch, onMounted, onBeforeUnmount } from "vue";
import ReactionBar from "./ReactionBar.vue";
import ParlerReactions from "@/components/tenantcomponents/parler/ParlerReactions.vue";
import CreatePostPopup from "../CreatePostPopup.vue";
import comment from "@/assets/parler/comment.svg";
import commentOutline from "@/assets/parler/commentOutline.svg";
import CommentFeed from "@/components/comments/CommentFeed.vue";
import FormatCount from "@/components/FormatCount.vue";
import DetailsReactions from "@/components/tenantcomponents/parler/DetailsReactions.vue";
import { useRouter, useRoute } from "vue-router";
import { IonIcon, IonToast } from "@ionic/vue";
import { arrowUndoSharp, arrowUndoOutline } from "ionicons/icons";
import UserAvatar from "@/components/UserAvatar.vue";
import SharePopup from "./SharePopup.vue";
import { usePostStore } from "@/stores/post";
import { useGlobalStore } from "@/stores/global";
import { useAuthStore } from "@/stores/auth";

const postStore = usePostStore();
const globalStore = useGlobalStore();
const authStore = useAuthStore();
const route = useRoute();

const props = defineProps({
  postId: {
    type: String,
    required: false,
  },
  detailsShow: {
    type: Boolean,
    default: false,
  },
  parentId: {
    tpye: String,
    required: false,
  },
  isNestedFeed: {
    type: Boolean,
    default: false,
  },
  parentFilter: {
    type: Boolean,
    default: null,
  },
  parentSort: {
    type: String,
    defualt: null,
  },
  depth: {
    type: Number,
    default: 0,
  },
  parentPost: {
    type: Boolean,
    default: false,
  },
  isCommentDetails: {
    type: Boolean,
    default: false,
  },
  quote: {
    type: Boolean,
    default: false,
  },
  atBottom: {
    type: Boolean,
    default: false,
  },
  repostId: {
    type: String,
    default: "",
  },
  isReply: {
    type: Boolean,
    default: false,
  },
});

const currentFilter = ref(props.parentFilter || "popular");

function handleNewFilter(filter) {
  console.log("handleNewFilter", filter);
  currentFilter.value = filter;
}

const intialCommentAccordion = computed(() => {
  if (props.detailsShow) {
    return true;
  }
  return false;
});

const postObjectRef = ref(null);
const commentAccordion = ref(intialCommentAccordion.value);
const replyAccordion = ref(false);
let toastMessage = ref("");
let toastDuration = ref(0);
let toastColor = ref("");
const toastOpen = ref(false);
const router = useRouter();
const engagementRef = ref(null);
const containerWidth = ref(0);
const showDetailsReactions = ref(false);
const commentIds = ref([]);
const replyId = ref("");
const commentFeedRef = ref(null);

onMounted(() => {
  // engagementObserver.observe(engagementRef.value);

  if (engagementRef) {
    containerWidth.value = engagementRef.value.offsetWidth;
  }
});

onBeforeUnmount(() => {
  // engagementObserver.disconnect();
  commentAccordion.value = false;
});

// Computed property to get the post object
const postObject = computed(
  () => postStore.postsCache[props.postId] || postObjectRef.value
);

const parentPost = computed(() => {
  return postStore.postsCache[postObject.value.parentUlid];
});

// // Watch for changes in postId
// watch(() => props.postId, async (newPostId) => {
//   if (!props.postId) return;

//   if (!postStore.postsCache[newPostId]) {
//     await postStore.fetchPostsByIds([newPostId]);
//     postObjectRef.value = postStore.postsCache[newPostId];
//   } else {
//     postObjectRef.value = postStore.postsCache[newPostId];
//   }
// }, { immediate: true });

watch(
  () => postObject.value,
  (newVal, oldVal) => {
    if (newVal && oldVal && newVal.id !== oldVal.id) {
      commentAccordion.value = false;
    }
  }
);

const addCommentIds = (ids) => {
  commentIds.value = [...commentIds.value, ...ids];
};

const emit = defineEmits([
  "comment-ids",
  "comments-open",
  "no-more-comments",
  "comments-in-dom",
]);

watch(
  () => props.atBottom,
  (newVal) => {
    if (newVal && commentAccordion.value) {
      if (postObject.value?.postEngagement?.commentCount > 0) {
        // No other specific action needed - the watch in CommentFeed will handle it
        // The loadMore prop will trigger the CommentFeed to load more
      }
    }
  }
);

// Make sure the component passes along the no-more-comments event
const handleNoMoreComments = () => {
  emit("no-more-comments");
};

watch(
  () => commentIds.value,
  (newVal, oldVal) => {
    emit("comment-ids", newVal);
  }
);

let timeoutId;

// const engagementObserver = new IntersectionObserver((entries) => {
//   const engagementEntry = entries[0];
//   if (!engagementEntry.isIntersecting) {
//     clearTimeout(timeoutId);
//     timeoutId = setTimeout(() => {
//       if (!engagementEntry.isIntersecting) {
//         commentAccordion.value = false;
//       }
//     }, 5000); // Adjust delay as needed, e.g., 5 seconds
//   } else {
//     clearTimeout(timeoutId);
//   }
// }); // Adjust threshold as needed

function handleShowToast(payload) {
  showToast(payload.message, payload.color);
}

const navigateToContent = (postId) => {
  router.push(`/post/${postId}`).catch((error) => {
    console.error("Router navigation error:", error);
  });
};

const commentAccordionRef = ref(null);

function openCommentAccordion() {
  if (props.isReply) {
    navigateToContent(
      `${postObject.value.parentUlid}?scrollToComment=${postObject.value.id}`
    );
    return;
  } else if (postObject.value.postType === "REPLY") {
    replyId.value = postObject.value.id;
    replyAccordion.value = true;
    return;
  }
  if (props.parentPost) {
    navigateToContent(postObject.value.id);
    return;
  }
  emit("comments-open");
  commentAccordion.value = !commentAccordion.value;

  // If the comment accordion is opened, scroll it into view
  // if (commentAccordion.value) {
  //   nextTick(() => {
  //     engagementRef.value?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //     });
  //   });
  // }
  //
  // Vlad's not: the above seems redundant in my browser, it scrolls into
  // without it and properly positions comment input box on top of the port view
  // needs more testing
}

function scrollInToView() {
  nextTick(() => {
    engagementRef.value?.scrollIntoView({ behavior: "smooth", block: "start" });
  });
}

// Function to display the toast
function showToast(message, color) {
  toastMessage.value = message;
  toastDuration.value = 2000;
  toastColor.value = color;
  toastOpen.value = true;
}

function closeToast() {
  toastOpen.value = false;
}

function handleCommentsInDom(commentIds) {
  emit("comments-in-dom", commentIds);
}

// Function to handle new comments from the CreatePostPopup
function handleNewComment(commentId) {
  // If the comment accordion is not open, open it to show the comment
  if (!commentAccordion.value) {
    commentAccordion.value = true;
  }

  // Use nextTick to ensure the comment accordion and CommentFeed are mounted
  nextTick(() => {
    // Try to use the direct ref to the component
    if (
      commentFeedRef.value &&
      typeof commentFeedRef.value.handleNewUserComment === "function"
    ) {
      console.log("Using CommentFeed ref to track comment:", commentId);
      commentFeedRef.value.handleNewUserComment(commentId);
    } else {
      // Fallback to looking for the component in the DOM
      // Skip logging since it's working as expected
      const commentFeedComponent = commentAccordionRef.value?.querySelector(
        ".comment-feed"
      )?.firstChild?.__vueParentComponent?.component?.exposed;

      if (
        commentFeedComponent &&
        typeof commentFeedComponent.handleNewUserComment === "function"
      ) {
        console.log("Found CommentFeed via DOM, tracking comment:", commentId);
        commentFeedComponent.handleNewUserComment(commentId);
      } else {
        // Skip error logging since functionality is working

        // Just use the fallback scrolling mechanism
        setTimeout(() => {
          const commentElement = document.querySelector(
            `[data-id="comment-${commentId}"]`
          );
          if (commentElement) {
            commentElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }, 800); // Longer timeout to ensure DOM is fully updated
      }
    }
  });
}
</script>

<style scoped>
ion-icon {
  --ionicon-stroke-width: 30px;
}

.active {
  color: var(--tertiary-color);
}

.primary-color {
  background-color: var(--primary-color);
}
</style>
