<template>
  <ion-modal
    ref="modalRef"
    :is-open="isOpened"
    :initial-breakpoint="initialBreakpoint"
    :breakpoints="[0, 0.25, 0.5, 0.85, 0, 95, 1]"
    @willDismiss="modalWillDismiss"
    handle="false"
  >
    <ion-content class="relative">
      <div
        class="rounded-t-[45px] bg-neutral-50 dark:bg-zinc-950 ion-padding pt-10 h-full overflow-auto"
      >
        <div
          class="create-post-wrapper top-12 md:top-10 cursor-pointer select-none"
          @click="modalWillDismiss"
        >
          <ion-icon
            v-if="globalStore.isParler && !isComment && !group?.isJoined"
            :src="post_button"
            class="create-post-button p-3 md:p-4 text-3xl md:text-4xl border-4 border-white dark:border-[#434343]"
          />
          <ion-icon
            v-else-if="group && group.isJoined"
            :icon="groupIcon"
            class="create-post-button p-3 md:p-4 text-3xl md:text-4xl border-4 border-white dark:border-[#434343]"
          />
          <ion-icon
            v-else
            :icon="isComment ? chatbubbleSharp : addCircleOutline"
            class="create-post-button p-3 md:p-4 text-3xl md:text-4xl border-4 border-white dark:border-[#434343]"
          />
        </div>
        <div class="pt-6"></div>
        <ion-card
          v-if="firstPost && !isComment && !isRepost"
          class="ring-red-500 ring-8 ring-opacity-50 p-2 text-sm shadow-xl"
        >
          <p>
            Ready to make your first post? Great! Your first post is a big deal.
            Include your interests, introduce yourself, and use hashtags to make
            it stand out.
          </p>
        </ion-card>
        <p
          v-if="group?.name && !isComment && group.isJoined"
          class="text-center text-xs"
        >
          You are posting in the group
          <span class="font-semibold">{{ group.name }}</span
          >.
        </p>
        <p
          v-else-if="group?.name && !isComment && !group.isJoined"
          class="text-center text-xs"
        >
          You must join the group
          <span class="font-semibold">{{ group.name }}</span> to post in it.
        </p>
        <section class="w-full flex flex-col">
          <div class="flex mb-2">
            <div class="mr-2 flex-none rounded-full h-14 w-14 object-cover">
              <UserAvatar :avatar="authStore.currentUser.avatar" />
            </div>

            <ion-textarea
              ref="postTextarea"
              :auto-grow="true"
              :autofocus="true"
              v-model="postBody"
              rows="4"
              class="max-h-64 overflow-auto text-black dark:text-white"
              :placeholder="placeholderText"
              :counter="true"
              maxlength="2000"
              :spellcheck="true"
              autocapitalize="sentences"
              @input="handleSearchInput"
              @keydown.alt="isAutocompleteOpen = true"
              @keydown.tab="handleTabKey($event)"
              @paste="handlePaste"
            />

            <ion-card
              class="custom-popover rounded-lg cursor-pointer select-none"
              v-if="isAutocompleteOpen"
            >
              <ion-list lines="none">
                <ion-item
                  v-for="result in autocompleteResults"
                  :key="result.id"
                  @click="selectAutocompleteResult(result)"
                >
                  <ion-avatar v-if="result.type === 'user'">
                    <UserAvatar :avatar="result.avatar" />
                  </ion-avatar>
                  <ion-label>
                    <h2 v-if="result.type === 'user'">{{ result.name }}</h2>
                    <h2 v-if="result.type === 'hashtag'">
                      {{ result.hashtag }}
                    </h2>
                    <h2 v-if="result.type === 'ticker'">{{ result.ticker }}</h2>
                    <h3 v-if="result.type === 'user'">
                      @{{ result.username }}
                    </h3>
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-card>
          </div>
          <MediaUploader
            ref="uploader"
            @changed-medias="addMedia"
            @uploadStarted="handleUploadStarted"
            @uploadFinished="handleUploadFinished"
            :alreadyUploaded="mediaFiles.length"
            :allowMultiple="true"
          />
          <GifPicker
            @close="closeGif"
            @selected="addMedia"
            :is-open="showGifPicker"
          />
          <div class="flex items-center justify-between pt-2">
            <div class="flex items-center space-x-1 sm:space-x-2 md:space-x-3">
              <button
                @click.prevent="canUpload ? uploader.start() : null"
                class="h-7 w-7 cursor-pointer select-none"
                :class="!canUpload ? 'opacity-50' : 'opacity-100'"
                :disabled="!canUpload"
              >
                <img
                  class="h-full w-full"
                  src="../assets/upload_media_icon.svg"
                  alt="upload_media"
                />
              </button>
              <button
                @click="showGifPicker = true"
                class="h-7 w-7 cursor-pointer select-none"
                :class="!canUpload ? 'opacity-50' : 'opacity-100'"
                :disabled="!canUpload"
              >
                <img
                  class="h-full w-full"
                  src="../assets/upload_gif_icon.svg"
                  alt="upload_gif"
                />
              </button>
              <div class="flex items-center">
                <ion-toggle
                  v-model="isSensitive"
                  color="primary"
                  class="mr-2 cursor-pointer select-none"
                  label="Mark Sensitive"
                />
                <p
                  class="text-center font-semibold text-sm"
                  :class="isSensitive ? 'text-primary' : 'text-zinc-500'"
                >
                  Sensitive
                </p>
              </div>
            </div>
            <div class="flex items-center">
              <button
                class="flex justify-center w-16 items-center cursor-pointer h-9 select-none transition duration-500 ease-in-out bg-gray-300 mr-1 text-white text-base font-bold focus:outline-none rounded-lg"
                @click="modalWillDismiss"
              >
                Cancel
              </button>
              <button
                class="transition h-9 w-14 duration-500 ease-in-out rounded-lg bg-primary cursor-pointer flex items-center justify-center select-none text-white text-base font-bold focus:outline-none"
                :class="
                  uploading ||
                  (postBody.length === 0 && mediaFiles.length === 0)
                    ? 'opacity-50'
                    : 'opacity-100'
                "
                @click="postOrComment"
                :disabled="
                  uploading ||
                  (postBody.length === 0 && mediaFiles.length === 0)
                "
              >
                <ion-icon
                  v-if="globalStore.isParler && !uploading"
                  :src="groupId ? groupIcon : post_button"
                  class="text-xl text-white px-3"
                />
                <ion-spinner
                  v-else
                  name="crescent"
                  color="light"
                  class="dark:invert"
                />
              </button>
            </div>
          </div>
          <p v-if="!canUpload" class="text-red-500 text-center">
            <ion-icon :icon="alertCircleSharp" /> You can only upload up to 5
            media files
          </p>
          <div
            v-if="mediaFiles.length > 0"
            class="flex flex-wrap justify-between items-start overflow-hidden w-[95%] mt-2"
          >
            <div
              v-for="preview in mediaFiles"
              :key="preview"
              :class="{
                'w-full': mediaFiles.length === 1,
                'w-1/2': mediaFiles.length === 2,
                'w-1/3': mediaFiles.length === 3,
                'w-1/4': mediaFiles.length === 4,
                'w-1/5': mediaFiles.length === 5,
              }"
            >
              <div class="relative">
                <button
                  @click="removeMedia(preview.url)"
                  class="cursor-pointer select-none absolute top-0 right-0 z-50"
                >
                  <img
                    class="bg-white rounded-full h-6 w-6"
                    src="@/assets/cancel.svg"
                    alt=""
                  />
                </button>
                <img
                  v-if="!preview.isVideo"
                  class="object-cover cursor-pointer"
                  :src="preview.url"
                  alt="Selected Media"
                />
                <VideoPreview v-else :videoUrl="preview.url" controls />
              </div>
            </div>
          </div>
          <!--    original post     -->
          <div
            v-if="isComment"
            :class="isKeyboardVisible ? 'max-h-60' : ''"
            class="border border-red-100 rounded-md m-2 p-1 overflow-hidden w-[95%]"
          >
            <ion-icon
              :icon="arrowUndoSharp"
              class="text-2xl transform scale-y-[-1] cursor-pointer"
            />
            <ion-card-header>
              <PostHeader
                :id="originalPost.id"
                :userId="originalPost.user?.userId"
                :createdAt="originalPost.createdAt"
                :isCreatePost="true"
              />
            </ion-card-header>
            <ion-card-content>
              <PostBody :parentItem="originalPost" :isReply="true" />
            </ion-card-content>
          </div>
          <div
            v-if="isRepost"
            class="border border-red-100 rounded-md m-2 w-[95%] p-1 overflow-hidden"
            :class="isKeyboardVisible ? 'max-h-60' : ''"
          >
            <img
              src="@/assets/quotes.svg"
              alt="quote"
              class="dark:invert -mb-8"
            />
            <ion-card-header>
              <PostHeader
                :id="originalPost.id"
                :userId="originalPost.user?.userId"
                :createdAt="originalPost.createdAt"
                :isCreatePost="true"
              />
            </ion-card-header>
            <ion-card-content>
              <PostBody :parentItem="originalPost" :isReply="true" />
            </ion-card-content>
          </div>
        </section>
      </div>
    </ion-content>
    <ion-alert
      :is-open="showAlert"
      header="Confirm"
      message="Do you want to save this draft?"
      :buttons="[
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'alert-cancel',
          handler: () => {
            removeDraft(), (showAlert = false);
          },
        },
        {
          text: 'Save Draft',
          cssClass: 'alert-confirm',
          handler: () => saveDraft(true),
        },
      ]"
    ></ion-alert>
  </ion-modal>
</template>

<script setup>
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/users";
import { usePostStore } from "@/stores/post";
import { useGlobalStore } from "../stores/global";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import {
  ref,
  onMounted,
  onBeforeUnmount,
  computed,
  nextTick,
  watch,
} from "vue";
import MediaUploader from "@/components/media/MediaUploader.vue";
import GifPicker from "@/components/media/GifPicker.vue";
import VideoPreview from "./media/VideoPreview.vue";
import {
  IonContent,
  IonModal,
  IonTextarea,
  IonCardHeader,
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonAvatar,
  IonLabel,
  IonAlert,
  IonIcon,
  IonSpinner,
  IonToggle,
} from "@ionic/vue";
import PostBody from "@/components/posts/PostBody.vue";
import PostHeader from "@/components/posts/PostHeader.vue";
import UserAvatar from "./UserAvatar.vue";
import {
  addCircleOutline,
  chatbubbleSharp,
  arrowUndoSharp,
  alertCircleSharp,
} from "ionicons/icons";
import post_button from "@/assets/parler/post_button.svg";
import groupIcon from "@/assets/parler/group_icon.svg";

const props = defineProps({
  isOpened: {
    type: Boolean,
    required: true,
  },
  isRepost: {
    type: Boolean,
    default: false,
  },
  isComment: {
    type: Boolean,
    default: false,
  },
  originalPost: {
    type: Object,
    default: null,
  },
  sharedFiles: {
    type: Array,
    default: () => [],
  },
  shareUrl: {
    type: String,
    default: "",
  },
  commentSort: {
    type: String,
    default: "",
  },
  replyId: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["close", "timeout", "newComment"]);

const submitting = ref(false);
const errorMessage = ref(null);
const reEncodedShareUrl = encodeURI(props.shareUrl);
const sanitizedShareUrl = sanitizeInput(reEncodedShareUrl);
const postBody = ref(sanitizedShareUrl);
const initialPostBody = ref(""); // Track the initial post body state
const uploader = ref(null);
const uploading = ref(false);
const showGifPicker = ref(false);
const authStore = useAuthStore();
const userStore = useUserStore();
const postStore = usePostStore();
const globalStore = useGlobalStore();
const postTextarea = ref(null);
const isAutocompleteOpen = ref(false);
const autocompleteResults = ref([]);
const searchTerm = ref("");
const isSearching = ref(false);
const firstPost = ref(authStore.currentUser.postCount === 0);
const showAlert = ref(false);
const isSensitive = ref(false);
const focusTimeout = ref(null);
const initialHeight = ref(window.innerHeight);
const isKeyboardVisible = ref(false);
const modalRef = ref(null);
const mediaFiles = ref([]);
const route = useRoute();
const timeoutError = ref(false);
const router = useRouter();

const groupId = computed(() => {
  if (route.name === "Group") {
    return route.params.id;
  } else {
    return null;
  }
});

const group = computed(() => {
  return postStore.groups[groupId.value];
});

const maxMedia = 5;

const canUpload = computed(() => mediaFiles.value.length < maxMedia);

const initialBreakpoint = computed(() => {
  if (props.isComment || props.isRepost || firstPost.value) {
    return 1;
  } else if (isKeyboardVisible.value) {
    return 0.85;
  } else if (!isKeyboardVisible.value) {
    return 0.5;
  } else {
    return 0.85;
  }
});

const draftPost = computed(() => {
  if (
    props.isRepost &&
    globalStore.draftPosts[props.originalPost.id + "-quote"]
  ) {
    return globalStore.draftPosts[props.originalPost.id + "-quote"];
  } else if (
    props.isComment &&
    globalStore.draftPosts[props.originalPost.id + "-comment"]
  ) {
    return globalStore.draftPosts[props.originalPost.id + "-comment"];
  } else if (
    !props.isComment &&
    !props.isRepost &&
    globalStore.draftPosts["default"]
  ) {
    return globalStore.draftPosts["default"];
  } else {
    return null;
  }
});

const processSharedContent = async () => {
  if (props.sharedFiles && props.sharedFiles.length > 0) {
    for (const file of props.sharedFiles) {
      if (file.type.match(/^image\/|^video\//)) {
        // It's a media file (image or video)
        uploader.value.uploadExternalFile(file);
      } else if (file.type === "text/plain") {
        // Handle plain text, which could include URLs
        const textContent = await file.text();
        if (isValidMediaURL(textContent)) {
          // If the text content is a valid media URL
          mediaFiles.value.push(textContent);
        } else {
          // If it's just plain text or non-media URL
          postBody.value += textContent;
        }
      }
    }
  }
};

function sanitizeInput(str) {
  const temp = document.createElement("div");
  temp.textContent = str;
  return temp.innerHTML;
}

const isValidMediaURL = (url) => {
  try {
    const parsedURL = new URL(url);
    return parsedURL.pathname.match(
      /\.(jpeg|jpg|gif|png|mp4|mov|avi|wmv|flv|mkv|webm)$/
    );
  } catch (e) {
    return false; // Not a valid URL
  }
};

const handleResize = () => {
  const heightDifference = initialHeight.value - window.innerHeight;
  isKeyboardVisible.value = heightDifference > 100; // Threshold can be adjusted
};

onMounted(() => {
  newUser();
  // First check for drafts and load them if available
  if (draftPost.value) {
    postBody.value = draftPost.value.postBody;
  }
  // If no draft exists, and it's a reply to a reply, add the @ mention
  else if (
    props.isComment &&
    props.originalPost?.postType === "REPLY" &&
    props.originalPost?.username
  ) {
    postBody.value = `@${props.originalPost.username} `;

    // Ensure the cursor is positioned after the @username text
    nextTick(() => {
      if (postTextarea.value !== null) {
        postTextarea.value.$el.setFocus();
        setCaretPosition(postBody.value.length);
      }
    });
  }

  // Store the initial post body for comparison when closing
  initialPostBody.value = postBody.value;

  window.addEventListener("resize", handleResize);

  // if (props.sharedFiles && props.sharedFiles.length > 0) {
  //   processSharedContent();
  // }

  nextTick(() => {
    focusTimeout.value = setTimeout(() => {
      if (postTextarea.value !== null) {
        postTextarea.value.$el.setFocus();
        // Position cursor at the end of any text
        if (
          props.isComment &&
          props.originalPost?.postType === "REPLY" &&
          props.originalPost?.username
        ) {
          setCaretPosition(postBody.value.length);
        }
      }
    }, 200); // delay of 200ms
  });
  // Remove the share_url parameter from the URL
  if (route.query["share-url"]) {
    const updatedQuery = { ...route.query };
    delete updatedQuery["share-url"];
    // Use `replace` to update the URL without leaving history
    router.replace({ query: updatedQuery });
  }
});

onBeforeRouteLeave((to, from, next) => {
  if (postBody.value || mediaFiles.value.length > 0) {
    emit("close");
  } else {
    showAlert.value = true;
  }
  next(false); // Prevent navigation
});

watch(
  mediaFiles,
  async (newVal, oldVal) => {
    if (newVal.length > 0) {
      if (initialBreakpoint.value === 0.85) {
        await modalRef.value.$el.setCurrentBreakpoint(1);
      }
      if (initialBreakpoint.value === 0.5) {
        await modalRef.value.$el.setCurrentBreakpoint(0.85);
      }
    }
  },
  { deep: true }
);

watch(
  postBody,
  async (newVal, oldVal) => {
    if (newVal.length > 300) {
      if (initialBreakpoint.value === 0.85) {
        await modalRef.value.$el.setCurrentBreakpoint(1);
      }
      if (initialBreakpoint.value === 0.5) {
        await modalRef.value.$el.setCurrentBreakpoint(1);
      }
    }
  },
  { deep: true }
);

const handleUploadStarted = () => {
  uploading.value = true;
};

const handleUploadFinished = () => {
  uploading.value = false;
};

const getCaretPosition = () => {
  const textarea = postTextarea.value.$el.querySelector("textarea");
  return textarea ? textarea.selectionStart : 0;
};

const setCaretPosition = (position) => {
  const textarea = postTextarea.value.$el.querySelector("textarea");
  if (textarea) {
    textarea.setSelectionRange(position, position);
    textarea.focus(); // You may need to set focus if not already focused.
  }
};

async function handlePaste(event) {
  if (!canUpload.value) return;

  const clipboardData = event.clipboardData || window.clipboardData;
  const items = clipboardData.items;

  if (!items) return;

  // Find an item that is an image
  let paste = null;
  for (let i = 0; i < items.length; i++) {
    if (items[i].type.indexOf("image") !== -1) {
      paste = items[i].getAsFile();

      break;
    }
  }

  if (
    paste &&
    (paste.type === "image/gif" ||
      paste.type === "image/png" ||
      paste.type === "image/jpeg")
  ) {
    uploader.value.uploadExternalFile(paste);
  }
}

const saveDraft = (close = false) => {
  let newDraftPost;

  if (props.isRepost) {
    newDraftPost = {
      draftId: props.originalPost.id + "-quote",
      originalPostId: props.originalPost.id,
      postType: "quote",
      postBody: postBody.value || "",
    };

    globalStore.draftPosts[props.originalPost.id + "-quote"] = newDraftPost;
  } else if (props.isComment) {
    newDraftPost = {
      draftId: props.originalPost.id + "-comment",
      originalPostId: props.originalPost.id,
      postType: "comment",
      postBody: postBody.value || "",
    };

    globalStore.draftPosts[props.originalPost.id + "-comment"] = newDraftPost;
  } else {
    newDraftPost = {
      draftId: "default",
      originalPostId: null,
      postType: "POST",
      postBody: postBody.value || "",
    };

    globalStore.draftPosts["default"] = newDraftPost;
  }

  showAlert.value = false;

  if (close) {
    postBody.value = "";
    emit("close");
  }
};

const removeDraft = () => {
  try {
    let draftKey;
    if (props.isRepost) {
      draftKey = props.originalPost?.id + "-quote";
    } else if (props.isComment) {
      draftKey = props.originalPost?.id + "-comment";
    } else {
      draftKey = "default";
    }

    globalStore.deleteDraft(draftKey);

    postBody.value = "";
    mediaFiles.value = [];

    emit("close");
  } catch (error) {
    console.error("Error in removeDraft:", error);
  }
};

const placeholderText = computed(() => {
  return props.isRepost
    ? "Enter your quote"
    : props.originalPost?.postType === "COMMENT" && props.isComment
    ? "Add a reply"
    : props.originalPost?.postType === "POST" && props.isComment
    ? "Add a comment"
    : "What's on your mind?";
});

function addMedia(media) {
  if (canUpload) {
    mediaFiles.value.push(media);
    showGifPicker.value = false;
  }
}

// Function to remove a selected media by its URL
function removeMedia(url) {
  // Create a new array without the matching media file
  mediaFiles.value = mediaFiles.value.filter(
    (mediaFile) => mediaFile.url !== url
  );

  // if mediaFiles is empty, reset the breakpoint
  if (mediaFiles.value.length === 0) {
    modalRef.value.$el.setCurrentBreakpoint(initialBreakpoint.value);
  }
}

function closeGif() {
  showGifPicker.value = false;
}

async function postOrComment() {
  uploading.value = true;
  if (props.isComment) {
    await addComment();
  } else {
    await createPost();
  }
  uploading.value = false;
}

function cleanGiphyURL(url) {
  const baseURL = url.split(".gif")[0];
  return baseURL + ".gif";
}

// Function to handle the error and display the alert
function handletimout() {
  emit("close");
}

async function addComment() {
  if (!props.originalPost || !props.originalPost.id) {
    console.error("Parent item or ID is missing");
    return;
  }
  try {
    // Create an array of just hte url property from each media file
    const mediaUrls = mediaFiles.value.map((file) => file.url);

    const mediaTypes = mediaFiles.value.map((file) =>
      file.isVideo ? "video" : "image"
    );

    const commentData = {
      groupName: "default",
      body: postBody.value,
      images: mediaUrls,
      ...(isSensitive.value === true && { isSensitive: true }),
    };

    if (props.originalPost.postType === "REPLY") {
      commentData.parentId = props.originalPost.parentUlid;
    } else {
      commentData.parentId = props.originalPost.id;
    }

    const response = await postStore.addComment(
      commentData,
      props.originalPost.postType === "REPLY"
        ? props.originalPost.parentUlid
        : props.originalPost.id,
      props.originalPost.parentUlid,
      props.originalPost.rootUlid,
      authStore.currentUser.ulid,
      props.commentSort,
      props.replyId
    );

    if (response.response?.data?.error?.timeout_until) {
      handletimout();
      return;
    }

    let tempMedia = {};

    if (mediaFiles.value.length > 0) {
      tempMedia = {
        images: mediaFiles.value.map((file) => ({
          url: file.url, // Include the URL
          widthPx: file.widthPx, // Include the width in pixels
          heightPx: file.heightPx, // Include the height in pixels
          mimeType: file.isVideo ? "video" : "image", // Optional: include the MIME type if you have it
        })),
      };
    }

    const newComment = {
      ...response,
      ...tempMedia,
      mediaTypes,
      ...(isSensitive.value === true && { isSensitive: true }),
    };
    postStore.postsCache[newComment.id] = newComment;

    postStore.updatePostInDB(newComment);

    // Emit the new comment ID for tracking
    emit("new-comment", newComment.id);

    // Clear the form fields
    postBody.value = "";
    mediaFiles.value = [];

    removeDraft();

    emit("close");
  } catch (error) {
    console.error("An error occurred while adding the comment:", error);
    saveDraft();
    // Handle the error, maybe show some feedback to the user
  } finally {
    // This block will run regardless of whether there was an error or not
  }
}

// Function to create a new post
async function createPost() {
  const mediaUrls = mediaFiles.value.map((file) => file.url);

  const mediaTypes = mediaFiles.value.map((file) =>
    file.isVideo ? "video" : "image"
  );

  // Clean the media URLs first if they are from Giphy
  const cleanedMediaFiles = mediaUrls.map((url) => {
    if (url.includes("giphy.com")) {
      return cleanGiphyURL(url);
    }
    return url;
  });

  const postData = {
    body: postBody.value,
    images: cleanedMediaFiles, // Use the cleaned URLs
    ...(isSensitive.value === true && { isSensitive: true }),
  };

  if (group.value?.id && group.value?.isJoined) {
    postData.groupId = group.value.id;
  }

  if (props.isRepost) {
    postData.ulid = props.originalPost.id;
  }

  try {
    const response = await postStore.createPost(postData, props.isRepost);

    if (response.response?.data?.error?.timeout_until) {
      handletimout();
      return;
    }

    if (firstPost.value) {
      authStore.setUser();
      firstPost.value = false;
    }

    let tempMedia = {};

    if (mediaFiles.value.length > 0) {
      tempMedia = {
        images: mediaFiles.value.map((file) => ({
          url: file.url, // Include the URL
          widthPx: file.widthPx, // Include the width in pixels
          heightPx: file.heightPx, // Include the height in pixels
          mimeType: file.isVideo ? "video/mp4" : "image", // Optional: include the MIME type if you have it
        })),
      };
    }

    // Add the new post to the postsCache with modified media

    const newPost = {
      ...response,
      ...tempMedia,
      mediaTypes,
      isRepostWithComment:
        postBody.value !== "" || mediaFiles.value.length > 0 ? true : false,
      ...(isSensitive.value === true && { isSensitive: true }),
    };

    postStore.postsCache[newPost.id] = newPost;
    postStore.updatePostInDB(newPost);

    removeDraft();
    emit("close");
  } catch (error) {
    errorMessage.value = error;
    saveDraft();
  } finally {
    submitting.value = false;
  }
}

const handleSearchInput = (event) => {
  // Save draft as user types
  saveDraft();

  const caretPos = getCaretPosition();

  if (event.data === "@" || event.data === "#" || event.data === "$") {
    isAutocompleteOpen.value = true;
  } else if (event.data === " ") {
    closeAutocomplete();
  } else if (event.inputType === "deleteContentBackward") {
    const query = postBody.value.substring(0, caretPos).trim();
    if (!query.endsWith("@") && !query.endsWith("#") && !query.endsWith("$")) {
      closeAutocomplete();
    }
  }

  // Get the text up to the caret position
  const textUpToCaret = postBody.value.substring(0, caretPos);

  // Find the last occurrence of @, #, or $ before the caret position
  const atIndex = textUpToCaret.lastIndexOf("@");
  const hashtagIndex = textUpToCaret.lastIndexOf("#");
  const tickerIndex = textUpToCaret.lastIndexOf("$");

  // Determine the active symbol based on the caret position
  const symbols = [atIndex, hashtagIndex, tickerIndex];
  const activeSymbolIndex = Math.max(...symbols);
  const activeSymbol = textUpToCaret[activeSymbolIndex];

  if (isAutocompleteOpen.value === true && activeSymbol) {
    const searchTermStart = activeSymbolIndex + 1;
    const searchTermEnd = textUpToCaret.indexOf(" ", searchTermStart);
    const searchTerm =
      searchTermEnd === -1
        ? textUpToCaret.substring(searchTermStart)
        : textUpToCaret.substring(searchTermStart, searchTermEnd);

    // Now you have the search term based on the active symbol and the caret position
    if (searchTerm.length >= 2 && !isSearching.value) {
      switch (activeSymbol) {
        case "@":
          debouncedSearchUsers(searchTerm);
          break;
        case "#":
          debouncedHashtagSearch(searchTerm);
          break;
        case "$":
          debouncedTickerSearch(searchTerm);
          break;
      }
    }
  }
};

const hashtagSearch = async (searchTerm) => {
  try {
    // Cancel the previous search request
    postStore.cancelSearch();

    const hashtags = await postStore.searchHashtags(searchTerm);

    if (Array.isArray(hashtags)) {
      autocompleteResults.value = hashtags.map((hashtag) => {
        // Map the result data to the desired format
        return {
          type: "hashtag",
          hashtag: hashtag.name,
        };
      });
    } else {
      autocompleteResults.value = [];
    }
  } catch (error) {
    console.error("Error searching hashtags:", error);
  } finally {
    isSearching.value = false;
  }
};
const tickerSearch = async (searchTerm) => {
  try {
    // Cancel the previous search request
    postStore.cancelSearch();

    const tickers = await postStore.searchTickers(searchTerm);

    if (Array.isArray(tickers)) {
      autocompleteResults.value = tickers.map((ticker) => {
        // Map the result data to the desired format
        return {
          type: "ticker",
          ticker: ticker.name,
        };
      });
    } else {
      autocompleteResults.value = [];
    }
  } catch (error) {
    console.error("Error searching tickers:", error);
  } finally {
    isSearching.value = false;
  }
};

const searchUsers = async (searchTerm) => {
  try {
    const users = await userStore.searchUsers(searchTerm);

    if (Array.isArray(users)) {
      autocompleteResults.value = users.map((user) => {
        const avatarURL = user.avatar;
        return {
          type: "user", // Add a 'type' property to indicate the result type
          ...user,
          avatar: avatarURL,
        };
      });
    } else {
      autocompleteResults.value = [];
    }

    // isAutocompleteOpen.value = true;
  } catch (error) {
    console.error("Error searching users:", error);
  } finally {
    isSearching.value = false;
  }
};

function debounce(fn, delay) {
  let timeoutId;

  const debounced = function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => fn(...args), delay);
  };

  debounced.cancel = function () {
    clearTimeout(timeoutId);
  };

  return debounced;
}

const debouncedHashtagSearch = debounce(hashtagSearch, 800);
const debouncedTickerSearch = debounce(tickerSearch, 800);
const debouncedSearchUsers = debounce(searchUsers, 800);

const closeAutocomplete = () => {
  isAutocompleteOpen.value = false;
  autocompleteResults.value = [];
  searchTerm.value = "";
  // postBody.value += ' ' // Add a space to the end of the post body

  postTextarea.value.$el.setFocus(); // Set focus back to the textarea
};

const handleTabKey = (event) => {
  if (isAutocompleteOpen.value && autocompleteResults.value.length > 0) {
    event.preventDefault();
    selectAutocompleteResult(autocompleteResults.value[0]);
  }
};

const selectAutocompleteResult = (result) => {
  // Get the caret position before we start modifying the text
  const caretPos = getCaretPosition();

  const textUpToCaret = postBody.value.substring(0, caretPos);

  const atIndex = textUpToCaret.lastIndexOf("@");
  const hashtagIndex = textUpToCaret.lastIndexOf("#");
  const tickerIndex = textUpToCaret.lastIndexOf("$");

  let newText = postBody.value; // Start with the existing text

  // Determine the start index of the search term that the user was typing
  const startIndex = Math.max(atIndex, hashtagIndex, tickerIndex);

  // Determine the end index of the search term that the user was typing
  const endIndex =
    newText.indexOf(" ", startIndex + 1) > -1
      ? newText.indexOf(" ", startIndex + 1)
      : newText.length;

  // Text before the current search term
  const textBeforeSearchTerm = newText.substring(0, startIndex + 1);

  // Text after the current search term
  const textAfterSearchTerm = newText.substring(endIndex);

  // Append the selected autocomplete result to the text before the search term
  let insertText;
  switch (result.type) {
    case "user":
      insertText = result.username;
      break;
    case "hashtag":
      insertText = result.hashtag;
      break;
    case "ticker":
      insertText = result.ticker;
      break;
  }

  newText = textBeforeSearchTerm + insertText + textAfterSearchTerm;

  // Combine the new text with the text after the search term
  postBody.value = newText;

  // Correctly set the caret position after replacing the search term
  // The caret should be placed right after the newly inserted text
  const newCaretPosition = startIndex + insertText.length + 1; // +1 for the space or symbol before the insertText

  closeAutocomplete();

  nextTick(() => {
    setCaretPosition(newCaretPosition);
  });
};

const newUser = () => {
  if (firstPost.value && !props.isComment && !props.isRepost) {
    postBody.value = "Hello World!👋  #NewUser";
  }
};

function modalWillDismiss() {
  // Check if content has actually changed from its initial state
  const contentChanged = postBody.value !== initialPostBody.value;
  const hasMedia = mediaFiles.value.length > 0;

  // Only show the save draft alert if content has been modified or media added
  if ((contentChanged && postBody.value !== "") || hasMedia) {
    // Show the alert asking if user wants to save as draft
    showAlert.value = true;
    return;
  }

  // If there's no modified content, just close without asking
  emit("close");
}

onBeforeUnmount(() => {
  if (handleResize) {
    window.removeEventListener("resize", handleResize);
  }

  debouncedHashtagSearch.cancel();
  debouncedTickerSearch.cancel();
  debouncedSearchUsers.cancel();

  if (focusTimeout.value) {
    clearTimeout(focusTimeout.value);
  }
});
</script>

<style scoped lang="scss">
.create-post-button {
  color: white;
  /* Icon color */
  background: var(--primary-color);
  /* Your button background color */
  border-radius: 50%;
  /* Circular shape */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Drop shadow for the button */
  /* Center the icon if it's not centered */
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-post-wrapper {
  position: absolute;
  right: 50%;
  /* Center the button horizontally */
  transform: translateX(50%);
  /* Align the center of the button with the right edge */
  z-index: 9999999;
  /* Higher than the modal's z-index */
}

:deep(.alert-confirm) {
  background-color: var(--primary-color) !important;
  color: white !important;
}

ion-content {
  overflow: visible;
  /* Adjust this value to add space for the floating button */
}

:deep(.alert-cancel) {
  background-color: white !important;
  color: black !important;
}

.custom-popover {
  position: absolute;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  // max-height: 200px;
  overflow-y: auto;
  z-index: 9999;
}

.custom-popover ion-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.custom-popover ion-item {
  padding: 8px 12px;
  cursor: pointer;
}

.custom-popover ion-item:hover {
  background-color: #f5f5f5;
}

.bg-primary {
  background-color: var(--primary-color);
}

ion-modal {
  --background: transparent;
  --backdrop-opacity: 0.8;
}

ion-content {
  --background: transparent;
  --padding-top: 60px;
}

.text-primary {
  color: var(--primary-color);
}

ion-toggle {
  height: 10px;
  width: 45px;
  --background-checked: var(--primary-color);
  --background: var(--neutral-50);
  --handle-background: rgb(113 113 122);
}
</style>
