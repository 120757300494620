<template>
  <div
    ref="componentRoot"
    class="post-container"
    :data-ulid="parentItem?.id"
    v-if="parentItem"
  >
    <!-- Root Post -->
    <PostSkeleton
      v-if="
        rootPost?.isSkeleton &&
        parentItem?.rootUlid &&
        !parentItem?.isRepost &&
        !parentItem?.isRepostWithComment &&
        parentItem?.parentUlid !== parentItem?.rootUlid &&
        rootPost
      "
      :images="rootPost?.images"
      :postId="rootPost?.id"
      :body="rootPost?.body"
      :embedUrl="rootPost?.embedUrl"
      :commentParent="true"
    />
    <div
      v-else-if="
        parentItem?.rootUlid &&
        !parentItem?.isRepost &&
        !parentItem?.isRepostWithComment &&
        parentItem?.parentUlid !== parentItem?.rootUlid &&
        rootPost
      "
      :key="`root-${rootPost.id}`"
      class="w-full"
    >
      <ion-card
        class="shadow-none rounded-none ion-no-margin w-full"
        @scroll="handlePostScroll"
      >
        <ion-card-header class="pt-3 pb-1 px-2">
          <PostHeader
            :id="rootPost?.id"
            :userId="rootPost?.user?.userId"
            :createdAt="rootPost?.createdAt"
            :repost-user-id="rootPost?.isRepost ? rootPost?.user?.userId : null"
            :repost-id="rootPost?.isRepost ? rootPost?.id : null"
            :views="rootPost?.postEngagement ? rootPost?.postEngagement.views : 0"
            :groupId="rootPost?.groupId"
          />
        </ion-card-header>
        <ion-card-content
          class="ml-7 pl-7 pb-7 px-1 md:px-2 border-l border-black dark:border-gray-400 border-t-0 border-r-0 border-b-0"
        >
          <PostBody
            :detailsShow="detailsShow"
            :parentItem="rootPost"
            :postInView="postInView"
          />
          <UserEngagement :postId="rootPost?.id" :parentPost="true" />
        </ion-card-content>
      </ion-card>
    </div>
    <!-- Parent Post, does not show if parent and root are the same -->
    <PostSkeleton
      v-if="
        parentPost?.isSkeleton &&
        parentItem?.parentUlid &&
        !parentItem?.isRepost &&
        !parentItem?.isRepostWithComment &&
        parentPost
      "
      :images="parentPost?.images"
      :postId="parentPost?.id"
      :body="parentPost?.body"
      :embedUrl="parentPost?.embedUrl"
      :commentParent="true"
    />
    <div
      v-else-if="
        parentItem &&
        parentItem?.parentUlid &&
        !parentItem?.isRepost &&
        !parentItem?.isRepostWithComment &&
        parentPost
      "
      class="w-full"
      :key="`parent-${parentPost.id}`"
    >
      <ion-card class="shadow-none rounded-md ion-no-margin" @scroll="handlePostScroll">
        <ion-card-header class="pt-3 pb-1 px-2 rounded-md">
          <PostHeader
            :id="parentPost.id"
            :userId="parentPost.user?.userId"
            :createdAt="parentPost?.createdAt"
            :repost-user-id="parentItem?.isRepost ? parentItem.user?.userId : null"
            :repost-id="parentItem?.isRepost ? parentItem.id : null"
            :views="parentPost?.postEngagement ? parentPost.postEngagement.views : 0"
            :groupId="parentPost?.groupId"
          />
        </ion-card-header>
        <ion-card-content
          class="ml-7 pl-7 pb-1 px-1 md:px-2 border-l border-black dark:border-gray-400 border-t-0 border-r-0 border-b-0"
        >
          <PostBody :detailsShow="detailsShow" :parentItem="parentPost" />
          <UserEngagement :postId="parentPost?.id" :parentPost="true" />
        </ion-card-content>
      </ion-card>
    </div>
    <PostSkeleton
      v-if="parentItem?.isSkeleton && !parentItem?.isRepost"
      :images="parentItem?.images"
      :postId="parentItem?.id"
      :body="parentItem?.body"
      :embedUrl="parentItem?.embedUrl"
    />
    <!-- Original Post if not a Empty Repost -->
    <div
      ref="originalPost"
      :key="`original-${parentItem.id}`"
      v-else-if="
        !parentItem?.isRepost ||
        (parentItem?.isRepost && parentItem?.isRepostWithComment && parentItem)
      "
      class="w-full border-b-4 rounded-md border-zinc-50 dark:border-b-4 dark:border-black relative"
      :class="{
        'border-2 rounded-md': isPinnedPost,
      }"
    >
      <div
        v-if="isPinnedPost"
        class="absolute flex justify-center items-center space-x-px -top-1 left-0 z-10"
      >
        <ion-icon class="text-primary text-lg p-1" :icon="pin" />
        <span class="text-xs text-primary">Pinned</span>
      </div>
      <ion-card
        class="shadow-none rounded-md ion-no-margin pb-1 mb-px overflow-auto"
        style="width: 100%"
        :class="{
          'pb-16 ': detailsShow,
          '-p-8': mediaOnly,
        }"
        @scroll="handlePostScroll"
      >
        <ion-card-header v-if="!mediaOnly" class="-ml-3">
          <PostHeader
            :id="parentItem?.id"
            :userId="parentItem?.user?.userId"
            :post-type="parentItem?.postType"
            :createdAt="parentItem?.createdAt"
            :isDetail="detailsShow"
            :views="parentItem?.postEngagement ? parentItem?.postEngagement.views : 0"
            :groupId="parentItem?.groupId"
          />
        </ion-card-header>
        <ion-card-content
          class="md:ion-no-margin pb-0"
          :class="{
            'px-0 md:px-2': !mediaOnly,
            '': mediaOnly,
            'ml-5 pl-4 px-0 px-2':
              parentItem?.postEngagement?.commentCount > 0 && detailsShow,
          }"
        >
          <div
            :class="{
              'pb-1 px-2':
                (parentPost && detailsShow) ||
                parentItem?.postType === 'COMMENT' ||
                parentItem?.postType === 'REPLY',
            }"
          >
            <PostBody
              :parentItem="parentItem"
              :detailsShow="detailsShow"
              :mainPost="true"
              :mediaOnly="mediaOnly"
              :postInView="postInView"
            />
          </div>

          <!-- Quote Post Parent -->
          <div
            v-if="parentPost && parentItem?.isRepostWithComment"
            :key="`quote-${parentPost?.id}`"
            :data-ulid="parentPost?.id"
            class="border rounded-md border-b-2 w-[95%] relative mt-2 mx-2"
            :class="
              parentPost?.isSkeleton
                ? 'border-gray-300 dark:border-gray-500 animate-pulse'
                : 'border-gray-800 dark:border-gray-400'
            "
          >
            <PostSkeleton
              v-if="parentPost?.isSkeleton && parentItem?.isRepostWithComment"
              :images="parentPost?.images"
              :postId="parentPost?.id"
              :body="parentPost?.body"
              :embedUrl="parentPost?.embedUrl"
            />
            <div v-else-if="parentItem?.isRepostWithComment">
              <ion-card class="shadow-none rounded-md ion-no-margin" style="width: 100%">
                <ion-card-header>
                  <PostHeader
                    :id="parentPost?.id"
                    :userId="parentPost.user?.userId"
                    :createdAt="parentPost?.createdAt"
                    :repost-id="parentItem?.isRepost ? parentItem?.id : null"
                    :views="
                      parentPost?.postEngagement ? parentPost?.postEngagement.views : 0
                    "
                    :quoted="true"
                    :groupId="parentPost?.groupId"
                  />
                </ion-card-header>
                <ion-card-content>
                  <PostBody
                    :detailsShow="detailsShow"
                    :parentItem="parentPost"
                    :postInView="postInView"
                  />
                  <UserEngagement
                    :postId="parentPost?.id"
                    :parentPost="true"
                    :quote="true"
                  />
                  <!-- Quote Post Grandparent -->
                  <!-- <div
                    v-if="parentPost?.isRepostWithComment && grandparentPost"
                    :data-ulid="grandparentPost?.id"
                    class="border rounded-md w-full relative mt-2"
                    :class="
                      grandparentPost?.isSkeleton
                        ? 'border-gray-300 dark:border-gray-500 animate-pulse'
                        : 'border-gray-800 dark:border-gray-400'
                    "
                  >
                    <PostSkeleton
                      v-if="grandparentPost?.isSkeleton"
                      :images="grandparentPost?.images"
                      :postId="grandparentPost.id"
                      :body="grandparentPost?.body"
                      :embedUrl="grandparentPost?.embedUrl"
                    />
                    <ion-card
                      v-else-if="grandparentPost && parentPost?.isRepostWithComment"
                      class="shadow-none rounded-md ion-no-margin"
                      style="width: 100%"
                    >
                      <ion-card-header>
                        <PostHeader
                          :id="grandparentPost?.id"
                          :userId="grandparentPost?.user?.userId"
                          :createdAt="grandparentPost?.createdAt"
                          :repost-id="parentItem?.isRepost ? parentItem?.id : null"
                          :views="
                            grandparentPost?.postEngagement ? grandparentPost?.views : 0
                          "
                          :quoted="true"
                          :groupId="grandparentPost?.groupId"
                        />
                      </ion-card-header>
                      <ion-card-content>
                        <PostBody
                          :detailsShow="detailsShow"
                          :parentItem="grandparentPost"
                          :postInView="postInView"
                        />
                        <UserEngagement
                          :postId="grandparentPost?.id"
                          :parentPost="true"
                          :quote="true"
                        />
                      </ion-card-content>
                    </ion-card>
                  </div> -->
                </ion-card-content>
              </ion-card>
            </div>
          </div>
          <div
            :class="{
              '-p-8': mediaOnly,
            }"
            v-if="parentItem?.postType != 'AD'"
          >
            <UserEngagement
              :postId="parentItem?.id"
              :details-show="detailsShow"
              @comment-ids="addCommentIds"
              :atBottom="atBottom"
              @no-more-comments="handleNoMoreComments"
              @comments-in-dom="handleCommentsInDom"
            />
            <ModerationTool v-if="isModeration" :parentItem="parentItem" />
          </div>
        </ion-card-content>
      </ion-card>
    </div>
    <!-- Empty Repost -->
    <PostSkeleton
      v-if="
        parentItem?.isRepost && !parentItem?.isRepostWithComment && parentPost?.isSkeleton
      "
      :images="parentPost?.images"
      :postId="parentPost?.id"
      :body="parentPost?.body"
      :embedUrl="parentPost?.embedUrl"
      :isRepost="true"
      :feedType="feedType"
      :repostUsername="parentItem?.user?.username"
    />
    <div
      v-else-if="parentItem?.isRepost && !parentItem?.isRepostWithComment && parentPost"
      :data-ulid="parentPost?.id"
      :key="`repost-${parentPost.id}`"
      class="w-full border-b-4 border-zinc-50 dark:border-b-4 dark:border-black"
    >
      <ion-card class="shadow-none rounded-md ion-no-margin" :class="commentItemClass">
        <ion-card-header class="pt-1 px-1">
          <PostHeader
            :repost-usernames="parentItem?.otherRepostUsers"
            :additional-reposts="parentItem?.otherRepostUsers.length"
            :id="parentPost?.id"
            :feedType="feedType"
            :userId="parentPost?.user?.userId"
            :createdAt="parentPost.createdAt"
            :repost-user-id="parentItem?.isRepost ? parentItem?.user?.userId : null"
            :repost-id="parentItem?.id"
            :views="parentPost?.postEngagement ? parentPost?.postEngagement.views : 0"
            :isEmptyRepost="true"
            :groupId="parentPost?.groupId"
          />
        </ion-card-header>
        <ion-card-content class="pb-1 px-0 md:px-2">
          <PostBody :parentItem="parentPost" :postInView="postInView" />
          <!-- Display grandparent if empty repost parent is a comment or reply -->
          <div
            v-if="grandparentPost"
            :data-ulid="grandparentPost?.id"
            class="w-11/12 border border-gray-800 dark:border-gray-400 rounded-md m-5 p-1 relative"
          >
            <img
              v-if="
                grandparentPost.postType === 'COMMENT' ||
                grandparentPost.postType === 'REPLY'
              "
              src="@/assets/reply.svg"
              class="absolute top-1 left-1 z-10 rotate-180 dark:filter dark:invert"
              alt="quote"
            />
            <img
              v-else
              class="absolute top-1 left-1 z-10 dark:filter dark:invert"
              src="@/assets/quotes.svg"
              alt="quote"
            />
            <PostSkeleton
              v-if="grandparentPost?.isSkeleton"
              :images="grandparentPost?.images"
              :postId="grandparentPost.id"
              :body="grandparentPost?.body"
              :embedUrl="grandparentPost?.embedUrl"
            />
            <ion-card
              v-else
              class="shadow-none rounded-md ion-no-margin"
              style="width: 100%"
              :class="commentItemClass"
            >
              <ion-card-header>
                <PostHeader
                  :id="grandparentPost?.id"
                  :userId="grandparentPost?.user?.userId"
                  :createdAt="grandparentPost?.createdAt"
                  :repost-id="parentItem?.isRepost ? parentItem?.id : null"
                  :views="grandparentPost?.postEngagement?.views"
                  :group-id="grandparentPost?.groupId"
                />
              </ion-card-header>
              <ion-card-content>
                <PostBody
                  :detailsShow="detailsShow"
                  :parentItem="grandparentPost"
                  :postInView="postInView"
                />
                <UserEngagement
                  :postId="grandparentPost?.id"
                  :parentPost="true"
                  :quote="true"
                />
              </ion-card-content>
            </ion-card>
            <!-- <div v-if="grandparentPost.parentUlid && rootPost"
              class="w-11/12 border border-gray-800 dark:border-gray-400 rounded-md m-5 p-1">
              <img src="@/assets/reply.svg" class="rotate-180 dark:filter dark:invert" alt="quote" />
              <ion-card class="shadow-none rounded-md ion-no-margin" style="width: 100%" :class="commentItemClass">
                <ion-card-header>
                  <PostHeader :id="rootPost?.id" :userId="rootPost?.user?.userId" :createdAt="rootPost?.createdAt"
                    :repost-id="parentItem?.isRepost ? parentItem?.id : null" :views="rootPost?.postEngagement.views" />
                </ion-card-header>
                <ion-card-content>
                  <PostBody :detailsShow="detailsShow" :parentItem="rootPost" />
                  <UserEngagement :postId="rootPost?.id" :parentPost="true" />
                </ion-card-content>
              </ion-card>
            </div> -->
          </div>

          <UserEngagement
            :postId="parentPost?.id"
            :details-show="detailsShow"
            :repostId="parentItem?.id"
            @comments-open="toggleCommentOpen"
          />
          <!-- Comment feed is shown only if there are comments and detailsShow is true -->
        </ion-card-content>
      </ion-card>
    </div>
    <PostSkeleton
      v-if="
        parentItem?.isRepost && !parentItem?.isRepostWithComment && parentPost?.isSkeleton
      "
      :images="parentPost?.images"
      :postId="parentPost?.id"
      :body="parentPost?.body"
      :embedUrl="parentPost?.embedUrl"
      :isRepost="true"
    />
    <div
      class="text-white flex justify-center items-center"
      :data-ulid="parentItem.parentUlid"
      v-else-if="parentItem?.isRepost && !parentItem?.isRepostWithComment && !parentPost"
    >
      <EmptyRepost
        :id="parentItem.id"
        :userId="parentItem.user.userId"
        :repostUserId="parentItem.user.userId"
        :repostId="parentItem.repostId"
        :postType="parentItem.postType"
        :views="parentItem.postEngagement.views"
        :additionalReposts="parentItem.otherRepostUsers.length"
        :repostUsernames="parentItem.otherRepostUsers"
      />
    </div>
  </div>
</template>
<script setup>
// Importing the required components and libraries

// @ts-check

import UserEngagement from "@/components/engadgement/UserEngagement.vue";
import PostHeader from "./PostHeader.vue";
import PostBody from "./PostBody.vue";
import PostSkeleton from "./PostSkeleton.vue";
import EmptyRepost from "./EmptyRepost.vue";
import { usePostStore } from "../../stores/post";
import ModerationTool from "../moderation/ModerationTool.vue";
import { IonCard, IonCardContent, IonCardHeader, IonIcon } from "@ionic/vue";
import pin from "@/assets/pin.svg";
import {
  onBeforeMount,
  onMounted,
  nextTick,
  onBeforeUnmount,
  onUnmounted,
  ref,
  computed,
  watch,
} from "vue";
import { useRoute } from "vue-router";

// Defining component properties
const props = defineProps({
  parentItemId: String,
  index: Number,
  commentItemClass: String,
  detailsShow: {
    type: Boolean,
    default: false,
  },
  feedShow: {
    type: Boolean,
    default: true,
  },
  mediaOnly: {
    type: Boolean,
    required: false,
    default: false,
  },
  isModeration: {
    type: Boolean,
    required: false,
    default: false,
  },
  feedType: {
    type: String,
    required: false,
  },
  pinnedPostId: {
    type: String,
    required: false,
  },
  atBottom: {
    type: Boolean,
    required: false,
    default: false,
  },
  mobile: {
    type: Boolean,
    required: false,
    default: false,
  },
  reFechPost: Function,
});

watch(
  () => props.atBottom,
  (newVal) => {}
);

const postStore = usePostStore();
const commentOpen = ref(false);
const commentIds = ref([]);
const postInView = ref(true);
const loading = ref(true);
let loadingTimer = null;
const postVisibilityTimeouts = new Map();

// Scroll tracking variables
let lastScrollTop = 0;
let scrollDownCounter = 0;
let scrollUpCounter = 0;

const emit = defineEmits([
  "refresh-posts",
  "scrollIntoView",
  "post-deleted",
  "remove-posts",
  "comments-loaded",
  "no-more-comments",
  "request-more-comments",
  "comments-in-dom",
  "comments-open",
  "scroll-direction",
  "toolBar",
  "toggleVisibility",
]);

const handleNoMoreComments = () => {
  emit("no-more-comments");
};

const isPinnedPost = computed(() => {
  return props.pinnedPostId === parentItem.value?.id;
});

const toggleCommentOpen = () => {
  commentOpen.value = !commentOpen.value;
};

const parentItem = computed(() => {
  if (props.isModeration) {
    return postStore.violationsCache[props.parentItemId];
  } else {
    return postStore.postsCache[props.parentItemId];
  }
});

const parentPost = computed(() => {
  return postStore.postsCache[parentItem.value?.parentUlid] || null;
});

const grandparentPost = computed(() => {
  return postStore.postsCache[parentItem.value?.grandparentUlid];
});

const rootPost = computed(() => {
  return postStore.postsCache[parentItem.value?.rootUlid];
});

const watchPostDeletion = (postRef) => {
  watch(
    () => postRef.value?.isDeleted,
    (newVal) => {
      if (newVal === true) {
        emit("post-deleted", postRef.value.id);
      }
    },
    { immediate: true }
  );
};

// Initialize deletion watchers if necessary
watchPostDeletion(parentItem);
watchPostDeletion(parentPost);
watchPostDeletion(grandparentPost);
watchPostDeletion(rootPost);
const originalPost = ref(null);

const componentRoot = ref(null);
let observer;
let refreshInterval;

const payload = computed(() => {
  let ulids = [];

  // Add ULIDs to the array if they exist
  if (parentItem.value?.id) ulids.push(parentItem?.value?.id);
  if (parentItem.value?.parentUlid) ulids.push(parentItem?.value.parentUlid);
  if (parentItem.value?.grandparentUlid) ulids.push(parentItem?.value.grandparentUlid);
  if (parentItem.value?.rootUlid) ulids.push(parentItem?.value.rootUlid);

  // Conditionally add commentIds to the array if commentOpen is true
  if (commentIds.value.length > 0 && commentOpen.value) {
    ulids = [...ulids, ...commentIds.value];
  }

  // Return the array of ULIDs, ensuring all entries are unique
  return [...new Set(ulids)];
});

const addCommentIds = (ids) => {
  commentIds.value = Array.from(new Set([...commentIds.value, ...ids]));
};
if (commentIds.value.length > 0) {
  if (props.detailsShow) {
    emit("refresh-posts", commentIds.value);
  }
}

const route = useRoute();

onMounted(() => {
  if (
    !props.detailsShow &&
    payload.value &&
    parentItem.value?.postEngagement?.commentCount === 0
  ) {
    emit("refresh-posts", payload.value);
  }

  // Add event listener for load-more-comments
  window.addEventListener("load-more-comments", handleLoadMoreCommentsEvent);

  // Initialize a map to keep track of timeouts for each post
  if (componentRoot.value !== null) {
    const observerOptions = {
      threshold: 0.1,
      rootMargin: "100px 0px 100px 0px", // Adds a 100px buffer on the top and bottom
    };

    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const postId = payload.value; // Assuming payload.value contains the post ID
        if (entry.isIntersecting) {
          // Set a timeout before adding the post to visiblePosts
          postInView.value = true;
          const timeoutId = setTimeout(() => {
            // Assuming postId can be an array or a single value, ensure it's always treated as an array
            const postIdsToAdd = Array.isArray(postId) ? postId : [postId];

            // Combine current visiblePosts with new postIdsToAdd, flatten the array, and filter for uniqueness
            postStore.visiblePosts = [
              ...new Set([...postStore.visiblePosts, ...postIdsToAdd].flat()),
            ];
          }, 1000); // 1 second delay
          // Store the timeout ID
          postVisibilityTimeouts.set(postId, timeoutId);
        } else {
          // If post quickly goes out of view, clear the timeout to prevent addition
          if (postVisibilityTimeouts.has(postId)) {
            clearTimeout(postVisibilityTimeouts.get(postId));
            postVisibilityTimeouts.delete(postId);
          }
          postInView.value = false;
          // Remove the post from visiblePosts if it was added
          postStore.visiblePosts = postStore.visiblePosts.filter(
            (item) => item !== postId
          );
          emit("remove-posts", postId);
        }
      });
    }, observerOptions);

    // Begin observing the root element of the component
    observer.observe(componentRoot.value);
  }

  nextTick(() => {
    let postsToFetch = [];
    if (parentItem.value?.parentUlid && parentPost.value === null) {
      postsToFetch.push(parentItem.value?.parentUlid);
    }
    if (parentItem.value?.grandparentUlid && grandparentPost.value === null) {
      postsToFetch.push(parentItem.value?.grandparentUlid);
    }
    if (parentItem.value?.rootUlid && rootPost.value === null) {
      postsToFetch.push(parentItem.value?.rootUlid);
    }

    if (postsToFetch.length > 0) {
      emit("refresh-posts", postsToFetch);
    }

    if (props.detailsShow && parentItem.value?.parentUlid) {
      emit("scrollIntoView", originalPost.value);
    }
  });

  // turn off loading after 1 second
  loadingTimer = setTimeout(() => {
    loading.value = false;

    // Check for comments after loading is complete
    setTimeout(() => {
      // Look for comment elements in the DOM
      const commentElements = document.querySelectorAll('[data-id^="comment-"]');

      if (
        commentElements.length > 0 ||
        parentItem.value?.postEngagement?.commentCount === 0
      ) {
        // Emit an event to notify that comments are loaded
        emit("comments-loaded");
      } else {
        // If we don't find comment elements but expect them, try again after a short delay
        if (parentItem.value?.postEngagement?.commentCount > 0) {
          setTimeout(() => {
            const retryCommentElements = document.querySelectorAll(
              '[data-id^="comment-"]'
            );

            // Emit regardless of result on second try
            emit("comments-loaded");
          }, 500);
        }
      }
    }, 200);
  }, 1000);

  // Check for scrollToComment parameter and ensure post details are properly initialized
  if (route.query.scrollToComment) {
    // Wait for component to be fully mounted
    nextTick(() => {
      // Emit that comments are being loaded and should be shown
      emit("comments-open");

      // After a short delay, check for comment elements in the DOM
      setTimeout(checkForCommentElements, 500);
    });
  }

  // Find all scrollable elements and add event listeners
  if (componentRoot.value) {
    // Add scroll listeners to component root
    componentRoot.value.addEventListener("scroll", handlePostScroll, {
      passive: true,
    });

    // Also find ion-card elements that might be scrollable
    const scrollableElements = componentRoot.value.querySelectorAll(
      "ion-card, ion-card-content, .post-container"
    );

    // Add listeners to all potential scrollable elements
    scrollableElements.forEach((el) => {
      el.addEventListener("scroll", handlePostScroll, { passive: true });
    });
  }
});

onBeforeUnmount(() => {
  if (observer) {
    // Check if observer is initialized
    observer.disconnect();
  }

  if (refreshInterval) {
    clearInterval(refreshInterval);
  }

  // Remove load-more-comments event listener
  window.removeEventListener("load-more-comments", handleLoadMoreCommentsEvent);

  if (postVisibilityTimeouts) {
    postVisibilityTimeouts.forEach((timeoutId) => {
      clearTimeout(timeoutId);
    });
  }

  if (componentRoot.value) {
    componentRoot.value.removeEventListener("scroll", handlePostScroll);

    const scrollableElements = componentRoot.value.querySelectorAll(
      "ion-card, ion-card-content, .post-container"
    );
    scrollableElements.forEach((el) => {
      el.removeEventListener("scroll", handlePostScroll);
    });
  }
});

// Add handler for load-more-comments events
const handleLoadMoreCommentsEvent = (event) => {
  if (parentItem.value) {
    // Trigger the UserEngagement component to load more comments
    const commentAccordion = document.querySelector("[data-comment-accordion]");
    if (commentAccordion) {
      // Make sure comments are visible
      emit("comments-loaded");
    }

    // Forward the atBottom signal to trigger loading more in the feed
    emit("request-more-comments", event.detail);
  }
};

onUnmounted(async () => {
  if (!props.detailsShow) {
    postStore.stripPostToSkeleton(parentItem.value?.id);
    if (parentPost) {
      postStore.stripPostToSkeleton(parentPost.value?.id);
    }
    if (grandparentPost) {
      postStore.stripPostToSkeleton(grandparentPost.value?.id);
    }
    if (rootPost) {
      postStore.stripPostToSkeleton(rootPost.value?.id);
    }
  }
});

const handleCommentsInDom = () => {
  emit("comments-in-dom");
};

const checkForCommentElements = () => {
  // Wait a moment for the DOM to update
  setTimeout(() => {
    const commentElements = document.querySelectorAll('[data-id^="comment-"]');
    emit("comments-loaded");

    // If we have a scrollToComment and found comments, emit that they're in the DOM
    if (route.query.scrollToComment && commentElements.length > 0) {
      emit("comments-in-dom");
    } else if (route.query.scrollToComment) {
      // If no comments found but we need to scroll to one, try loading more
      if (parentItem.value?.postEngagement?.commentCount > 0) {
        emit("request-more-comments", route.query.scrollToComment);
      }
    }
  }, 500);
};

const handlePostScroll = (event) => {
  // Only process scroll events for mobile
  if (!props.mobile) return;

  // Get the current scroll position
  const scrollTop = event.target.scrollTop;

  // Handle the case when we're at the top
  if (scrollTop === 0) {
    // When at the top, always show the toolbars
    emit("toolBar", true);
    emit("toggleVisibility", true);
    return;
  }

  // Don't process tiny scrolls that might be from rendering adjustments
  if (Math.abs(scrollTop - lastScrollTop) < 5) {
    return;
  }

  // Determine scroll direction
  if (scrollTop > lastScrollTop) {
    // Scrolling down
    scrollDownCounter++;
    scrollUpCounter = 0; // Reset scroll up counter

    if (scrollDownCounter >= 5) {
      emit("toolBar", false); // Hide toolbar when scrolling down enough
      emit("toggleVisibility", false);
    }
  } else if (scrollTop < lastScrollTop) {
    // Scrolling up
    scrollUpCounter++;
    scrollDownCounter = 0; // Reset scroll down counter

    if (scrollUpCounter >= 3) {
      emit("toolBar", true); // Show toolbar when scrolling up enough
      emit("toggleVisibility", true);
    }
  }

  // Save the current scroll position for next comparison
  lastScrollTop = scrollTop;
};
</script>
<style scoped lang="scss">
/* Style for the root post */

.text-primary {
  color: var(--primary-color);
}

.post {
  &-root {
    display: flex;
    align-items: flex-start;
  }

  &-quote {
    display: flex;
    align-items: flex-start;
    border: 1px;
  }

  &-container {
    display: inline;
    width: 100%;
  }
}

ion-card {
  border-top: 1px solid var(--ion-item-background);

  &-header {
    padding-top: 19px;
  }
}
</style>
